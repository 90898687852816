<template>
  <v-app>
    <v-app-bar app
      dense elevate-on-scroll
      color="primary"
    >
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <v-toolbar-title class="text-body-1">
        会員番号:{{ zeroCustomerNo(customerId) }} {{ uName }}様 ＠ {{ shopName }}
      </v-toolbar-title>
    </v-app-bar>

    <v-main
      :style="$vuetify.theme.isDark || {background: 'var(--main-bg)'}"
      class="justify-center"
    >
      <router-view
        v-if="aToken && shopId && customerId"
        :shopId="shopId"
        :aToken="aToken"
        @closeLiff='closeLiff($event)'
      ></router-view>
    </v-main>

    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-app>
</template>

<script>
import liff from '@line/liff'
import axios from 'axios'
import { API_ENDPOINT } from '@/literals.js'
import { CiApiTool } from '@/module.js'
import Loader from '@/components/_Loader.vue'

export default {
  name: 'App',

  components: {
    'loader': Loader,
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      liffId: '',
      aToken: '',
      uName: '',
      uId: '',
      customerId: 0,
      shopId: 0,
      shopName: '',
      loading: false,
      loadingMessage: null,
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    zeroCustomerNo() {
      return no => ('000' + no).slice(-4)
    }
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  async created() {
    this.loadingMessage = '会員情報を取得中・・・'
    this.loading = true

    await this.getLiffIntegration()

    await this.initLiff()
    .catch( error => {
      alert('LINE認証システム初期化中にエラーが発生しました。\n\n' + error)
      liff.closeWindow()
    })
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    //
    // liff IDを取得
    //
    getLiffIntegration() {
      this.shopId = this.getQueryShopId()

      if (!this.shopId) {
        alert('システムエラーが発生しました')
        return
      }

      return axios({
        method: 'GET',
        url: API_ENDPOINT + '/site/' + this.shopId + '/liff-integration/',
      })
      .then( response => {
        this.liffId = response.data.liff_id
      })
      .catch(() => alert('LINE認証システム初期化中にエラーが発生しました。') )
    },

    //
    // liff init
    //
    initLiff() {
      return new Promise((resolve, reject) => {
        liff.init({ liffId: this.liffId })
        .then(() => {
          // this.shopId = this.$route.query.shopid
          if (!this.shopId) reject('対象店舗が選択されていません')

          if (!liff.isInClient()) {
            reject('会員機能はLINEアプリからご利用下さい。')
          }
          // else if (!liff.isLoggedIn()) {
          //   alert('会員機能をご利用するにはLINEログインして下さい')
          //   sessionStorage.setItem('shopid', this.shopId)
          //   liff.login()
          // }
          else {
            this.aToken = liff.getAccessToken()
            this.uName = liff.getDecodedIDToken().name

            const apiTool = new CiApiTool(API_ENDPOINT, this.shopId, this.aToken)

            apiTool.getReqCi('customer-id/').then( response => {
              if (this.$route.name !== 'CreateCustomer' && response.notExistsCustomer) {
                reject('こちらのLINEアカウントでの会員情報が存在しません。\nお手数ですが店舗アカウントを友だち追加してから再度お試し下さい。')
              } else {
                this.customerId = response
              }
            })
            .catch( error => reject(error) )
            .then(() => this.loading = false )

            apiTool.getReqPublic('shop-name/').then( data => this.shopName = data.shop_name )
            .catch( error => reject(error) )
          }
        })
      })
    },

    //
    //アラートを出してLIFFを閉じる
    //
    closeLiff(message) {
      if (message) alert(message)
      liff.closeWindow()
    },

    getQueryShopId() {
      const kv = decodeURIComponent(window.location.search).split('shopid=')

      if (kv.length != 2) {
        return 0
      } else {
        return Number(kv[1]) > 0 ? Number(kv[1]) : 0
      }
    }
  }
}
</script>

<style>
/***************************************
            Custom Property
****************************************/
:root {
  --main-bg: #f0f2f5;
  --content-bg-diff: #EEEEEE;
  --content-bg-dark-diff: #222222;
  --content-border-dark: #272727;
}

/***************************************
            Vuetify Modifier
****************************************/
.theme--dark.v-card.v-sheet {
    border: thin solid var(--content-border-dark);
}
.v-main__wrap {
  width: 100%;
  max-width: 1800px !important;
}

/***************************************
             共通コンテンツ
****************************************/
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

body {
  width: 100%;
  /* max-width: 1380px; */
  font-family: こぶりなゴシック W1 JIS2004, "Helvetica Neue", Helvetica, Arial,
    Verdana, Roboto, 游ゴシック, "Yu Gothic", 游ゴシック体, YuGothic,
    "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Meiryo UI", メイリオ,
    Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1a1a1a;
  margin: 0 auto;
  background: #fafafa;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.logo-font {
  font-family: "Fredoka One", "Kosugi Maru", sans-serif;
}
</style>
