import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: 'home' */ '@/views/Home.vue')
  },
  {
    path: '/create-customer',
    name: 'CreateCustomer',
    component: () => import(/* webpackChunkName: 'create-customer' */ '@/views/CreateCustomer.vue')
  },
  {
    path: '/coupon-check',
    name: 'CouponCheck',
    component: () => import(/* webpackChunkName: 'coupon-check' */ '@/views/CouponCheck.vue')
    // component: () => import("@/views/CouponCheck.vue")
  },
  {
    path: '/casts-today',
    name: 'CastsInToday',
    component: () => import(/* webpackChunkName: 'casts-today' */ '@/views/CastsInToday.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
