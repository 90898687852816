import axios from 'axios'
import moment from 'moment'

//***************************************************
//
// Export functions
//
//***************************************************
export {
}


//***************************************************
//
// Export classes
//
//***************************************************
// Class for shop CI API calls
//
export class CiApiTool {
  constructor(endpoint, shopId, aToken) {
    this.endpoint = endpoint
    this.shopId = shopId
    this.aToken = aToken
  }

  //
  // 店舗情報用 GET APIコール
  //
  getReqPublic(infoType, queries) {
    return new Promise((resolve, reject) => {
      axios({
        ...{ method: 'GET' },
        ...{ url: this.endpoint + '/site/' + this.shopId + '/' + infoType },
        ...{ params: queries }
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
    })
  }

  //
  // CI用 GET APIコール
  //
  getReqCi(infoType, queries) {
    return new Promise((resolve, reject) => {
      axios({
        ...{ method: 'GET' },
        ...{ url: this.endpoint + '/shop/' + this.shopId + '/ci/' + this.aToken + '/' + infoType },
        ...{ params: queries }
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
    })
  }

  //
  // CI用 APIコール
  //
  apiReqCiWithData(method, infoType, data) {
    return new Promise((resolve, reject) => {
      axios({
        ...{ method: method },
        ...{ url: this.endpoint + '/shop/' + this.shopId + '/ci/' + this.aToken + '/' + infoType },
        ...{ data: data },
      })
      .then(response => resolve(response.data))
      .catch(error => reject(error))
    })
  }
}

//***************************************************
// Class for business hour methods
//
export class BizHour {
  constructor(shopData) {
    this.openingHour = shopData.opening_hour
    this.closingHour = shopData.closing_hour

    this.openingHourNum = Number(shopData.opening_hour.split(':')[0])
    this.closingHourNum = Number(shopData.closing_hour.split(':')[0])

    this.closingHour24 = this.closingHourNum <= this.openingHourNum ? this.closingHourNum + 24 + ':00' : this.closingHour

    // For fullcalendar slot hours(24 hour = '25:00')
    this.slotOpenHHMM = this.openingHourNum - 1 + ':00'
    this.slotCloseHHMM = this.closingHourNum <= this.openingHourNum ? this.closingHourNum + 1 + 24 + ':00' : this.closingHourNum + 1 + ':00'
  }

  //
  // 日付から営業開始日時の取得（日またぎ問題の考慮）
  // return string （'YYYY-MM-DD HH:mm'）
  getBizOpening(date) {
    const datetime = new Date(date)
    let tmp = new Date(datetime)

    //閉店時刻が翌日の場合
    if (this.closingHourNum <= this.openingHourNum) {
      if (datetime.getHours() <= this.closingHourNum) {
        tmp = moment(datetime).subtract(1, 'd')
      }
    } else {
      //閉店時刻が同日（24時前閉店）の場合
      if (datetime.getHours() >= this.closingHourNum) {
        tmp = moment(datetime).add(1, 'd')
      }
    }
    return moment(
      moment(tmp).format('YYYY-MM-DD') + ' ' + this.openingHour).format('YYYY-MM-DD HH:mm')
  }

  //
  // 日付から営業終了日時の取得（日またぎ問題の考慮）
  // return string （'YYYY-MM-DD HH:mm'）
  getBizClosing(date) {
    const datetime = new Date(date)
    let tmp = new Date(datetime)

    //閉店時刻が翌日の場合
    if (this.closingHourNum <= this.openingHourNum) {
      if (datetime.getHours() >= this.closingHourNum) {
        tmp = moment(datetime).add(1, 'd')
      }
    }
    //同日閉店で閉店時刻後の場合
    else if (datetime.getHours() >= this.closingHourNum) {
      tmp = moment(datetime).add(1, 'd')
    }
    return moment(
      moment(tmp).format('YYYY-MM-DD') + ' ' + this.closingHour
    ).format('YYYY-MM-DD HH:mm') //.add(1, 'm')
  }

  //
  // 日付と時刻から日またぎ修正した日時を返す
  //
  getAdjustedDatetime(dateStr, timeStr) {
    const hourNum = Number(timeStr.split(':')[0])
    let tmp = moment(dateStr)

    if (hourNum < this.openingHourNum) {
      tmp.add(1, 'd')
    }
    return tmp.format('YYYY-MM-DD') + ' ' + ('0'+ hourNum).slice(-2) + ':' + timeStr.split(':')[1]
  }

  //
  // 引数dtがbaseDate日の営業時間内かどうか
  // return Boolean
  isInBizHours(dt, baseDate) {
    const datetime = new Date(dt)

    const opening = this.getBizOpening(baseDate)
    const closing = this.getBizClosing(baseDate)

    return moment(datetime).diff(opening) >= 0 && moment(datetime).diff(closing) <= 0 ? true : false
  }

  //
  // 営業時間のHour数値を配列で返す
  //
  getBizHourArray() {
    const hourArray = []

    //閉店時刻が翌日の場合
    if (this.closingHourNum <= this.openingHourNum) {
      for (let i = this.openingHourNum; i <= 23; i++) {
        hourArray.push(i)
      }
      for (let i = 0; i <= this.closingHourNum; i++) {
        hourArray.push(i)
      }
    } else {
      for (let i = this.openingHourNum; i <= this.closingHourNum; i++) {
        hourArray.push(i)
      }
    }
    return hourArray
  }

  //
  // フォーム用の分数値を配列で返す
  //
  getBizMinArray() {
    const minArray = []

    for (let i = 0; i <= 45; i += 15) {
      minArray.push(i)
    }
    return minArray
  }
}
