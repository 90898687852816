export default {
  MESSAGE: {
    loaderInTarnsaction: '処理中・・・',
  }
}

export {
  SYSTEM_PROVIDER,
  API_ENDPOINT,
  LIFF_ID,
  LAYZLOAD_IMG,
}

const API_ENDPOINT = process.env.NODE_ENV === 'production' ?
  'https://site-api.finebooking.shop' : 'http://site-api.localhost:8082'

const LIFF_ID = process.env.NODE_ENV === 'production' ?
  '1655642804-7D5yV86v' : '1655642804-7D5yV86v'

const SYSTEM_PROVIDER = {
  name: 'FINE TECHNOLOGY',
  since: '2021',
  systemName: 'FINEBOOKING'
}

const LAYZLOAD_IMG = 'assets/lazy-src.png'
