import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  noDataText: '該当するデータがありません',
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        primary: '#2196F3',
        secondary: '#F6A15E',
        accent: '#283593',
        anchor: '#283593',
        error: '#F50057',
        warning: '#F06292',
        info: '#742DFF',
        success: '#009688',
      },
      dark: {
        primary: '#7146BD',
        secondary: '#33691E',
        accent: '#3018A8',
        anchor: '#3018A8',
        error: '#F50057',
        warning: '#F06292',
        info: '#742DFF',
        success: '#009688'
      },
    }
  }
});
